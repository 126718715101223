<template>
  <div class="candidate-dashboard-intro">
    <h3 class="mb-3">{{ $t("candidate.dashboard.title") }}</h3>
    <p>{{ $t("candidate.dashboard.paragraph1") }}</p>
    <video class="custom-video" controls>
      <source src="@/assets/11.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <p>{{ $t("candidate.dashboard.paragraph2") }}</p>
    <video class="custom-video" controls>
      <source src="@/assets/11.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "CandidateDashboardIntro"
};
</script>

<style scoped>
.candidate-dashboard-intro {
  padding: 20px;
}

.candidate-dashboard-intro p {
  margin-bottom: 80px;
}

.candidate-dashboard-intro video {
  display: block;
  margin-bottom: 80px;
  width: 100%;
  background-color: #f0f0f0;
}
</style>
